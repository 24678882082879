import { Autocomplete, TextField } from '@mui/material';
import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useEffect, useState } from 'react';
import { StringUtilities } from '../../../utilities';
import Styles from "./CreateOrUpdateRole.module.scss";

export const CreateOrUpdateRole = ({ mode, role, permissions, onRoleCreateOrUpdate, onCancel, }) => {
  const [message, setMessage] = useState('');
  const [hasUserProvidedValue, setUserProvidedValue] = useState(false);
  const [roleData, setRoleData] = useState({});

  const updateRoleData = entry => {
    setRoleData({
      ...roleData,
      ...entry,
    });
  };

  const onFocusLost = event => {
    // auto generates permission value...
    if (!hasUserProvidedValue && event.target.name === 'label') {
      const autoGeneratedValue = event.target.value.replace(/\s/g, '_').toUpperCase();

      updateRoleData({ value: autoGeneratedValue, });
    }
  };

  const onInputValueChanged = event => {
    if (event.target.name === 'value') {
      setUserProvidedValue(!StringUtilities.isUndefinedOrNullOrEmpty(event.target.value));
    }

    updateRoleData({ [event.target.name]: event.target.value, });
  };

  const onCancelButtonClickedAsync = async event => {
    if (typeof onCancel !== 'function') { return; }

    await onCancel({ roleId: role.roleId, event, });
  };

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    if (typeof onRoleCreateOrUpdate !== 'function') { return; }

    const _roleData = {
      roleId: mode === 'UPDATE' ? role.roleId : '',
      label: roleData.label,
      value: roleData.value,
      permissionIds: roleData.permissions?.map(permission => permission.permissionId) ?? [],
    };
    const restApiClient = RestApiClient.getInstance();
    const response = await restApiClient.sendSmartRequestAsync({
      routeName: mode === 'UPDATE' ? 'updateRole' : 'createRole',
      data: _roleData,
    });

    setMessage(response.jsonData.message);

    if (response.status !== 200) { return; }

    const _role = response.jsonData.data.role;
    // _role only contains permission Ids in permissions array. so we are explicitly
    // defining the permissions array containing all permission info...
    _role.permissions = roleData.permissions;

    await onRoleCreateOrUpdate({ mode, roleId: role.roleId, role: _role, event, });
  };

  useEffect(() => {
    setRoleData(role);
  }, [mode, role]);

  return <div className={Styles.main}>
    <p>{message}</p>
    <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
      <label htmlFor="label">Name</label>
      <input type="text" name="label" value={roleData.label ?? ''} onBlur={onFocusLost} onChange={onInputValueChanged} /><br />

      <label htmlFor="value">Value</label>
      <input type="text" name="value" value={roleData.value ?? ''} onChange={onInputValueChanged} /><br />

      <label htmlFor="permissions">Permissions</label>
      <Autocomplete
        value={roleData.permissions ?? []}
        size='small'
        multiple={true}
        options={permissions}
        getOptionLabel={option => option.label ?? ''}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={async (_, value) => onInputValueChanged({ target: { name: 'permissions', value: value ?? [], } })}
        renderInput={params => <TextField {...params} label={''} />} />

      <button type='submit'>{mode === 'UPDATE' ? 'Update' : 'Create'}</button>
      {mode === 'UPDATE' && <button type='button' onClick={onCancelButtonClickedAsync}>Cancel</button>}
    </form>
  </div>;
};

import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useEffect, useState } from 'react';
import { StringUtilities } from '../../../utilities';
import Styles from "./CreateOrUpdatePermission.module.scss";

export const CreateOrUpdatePermission = ({ mode, permission, onPermissionCreateOrUpdate, onCancel, }) => {
  const [message, setMessage] = useState('');
  const [hasUserProvidedValue, setUserProvidedValue] = useState(false);
  const [permissionData, setPermissionData] = useState({});

  const updatePermissionData = entry => {
    setPermissionData({
      ...permissionData,
      ...entry,
    });
  };

  const onFocusLost = event => {
    // auto generates permission value...
    if (!hasUserProvidedValue && event.target.name === 'label') {
      const autoGeneratedValue = event.target.value.replace(/\s/g, '_').toUpperCase();

      updatePermissionData({ value: autoGeneratedValue, });
    }
  };

  const onInputValueChanged = event => {
    if (event.target.name === 'value') {
      setUserProvidedValue(!StringUtilities.isUndefinedOrNullOrEmpty(event.target.value));
    }

    updatePermissionData({ [event.target.name]: event.target.value, });
  };

  const onCancelButtonClickedAsync = async event => {
    if (typeof onCancel !== 'function') { return; }

    await onCancel({ permissionId: permission.permissionId, event, });
  };

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    if (typeof onPermissionCreateOrUpdate !== 'function') { return; }

    const restApiClient = RestApiClient.getInstance();
    const response = await restApiClient.sendSmartRequestAsync({
      routeName: mode === 'UPDATE' ? 'updatePermission' : 'createPermission',
      data: {
        permissionId: mode === 'UPDATE' ? permission.permissionId : '',
        label: permissionData.label,
        value: permissionData.value,
      },
    });

    setMessage(response.jsonData.message);

    if (response.status !== 200) { return; }

    await onPermissionCreateOrUpdate({ mode, permissionId: permission.permissionId, permission: response.jsonData.data.permission, event, });
  };

  useEffect(() => {
    setPermissionData(permission);
  }, [mode, permission]);

  return <div className={Styles.main}>
    <p>{message}</p>
    <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
      <label htmlFor="label">Name</label>
      <input type="text" name="label" value={permissionData.label ?? ''} onBlur={onFocusLost} onChange={onInputValueChanged} /><br />

      <label htmlFor="value">Value</label>
      <input type="text" name="value" value={permissionData.value ?? ''} onChange={onInputValueChanged} /><br />

      <button type='submit'>{mode === 'UPDATE' ? 'Update' : 'Create'}</button>
      {mode === 'UPDATE' && <button type='button' onClick={onCancelButtonClickedAsync}>Cancel</button>}
    </form>
  </div>;
};

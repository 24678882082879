export const Spinner = ({ id, }) => {

  /**
   * style definitions of 'container-d422aee5' and
   * 'spinner-ef6df753' are defined in 'spinner.css' in public folder
   */
  return <div id={id} className='container-d422aee5'>
    <div className='spinner-ef6df753'>
      <div />
      <div />
    </div>
  </div>;
};

import React, { useState } from "react";
import Styles from "./Menu.module.scss";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import ToggleButton from "@mui/material/ToggleButton";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import QuizIcon from '@mui/icons-material/Quiz';
import PhoneIcon from '@mui/icons-material/Phone';
import AnchorIcon from '@mui/icons-material/Anchor';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HelpIcon from '@mui/icons-material/Help';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import GppGoodIcon from '@mui/icons-material/GppGood';
import PeopleIcon from '@mui/icons-material/People';
import WidgetsIcon from '@mui/icons-material/Widgets';
import InventoryIcon from '@mui/icons-material/Inventory';
import CallMissedIcon from '@mui/icons-material/CallMissed';


const ListItem = ({ text, link, icon }) => {
  let navigate = useNavigate();
  const Icon =  icon ;
  return (
    <ListItemButton onClick={()=>{navigate(link)}}>
      <ListItemIcon> <Icon className={Styles.icon} /> </ListItemIcon>
      <ListItemText
        primary={text}
      />
    </ListItemButton>
  );
};

export const Menu = () => {
  const [viewFullMenu, setViewFullMenu] = useState(true);
  const [openUser, setOpenUser] = useState(false);
  const [openCMS, setOpenCMS] = useState(false);
  const [openProperty, setOpenProperty] = useState(false);
  const [openProjects, setOpenProjects] = useState(false);
  const [openKyc, setOpenKyc] = useState(false);
  const [openAdministrative, setOpenAdministrative] = useState(false);

  const handleClickUser = () => {
    setOpenUser(!openUser);
  };
  const handleClickCMS = () => {
    setOpenCMS(!openCMS);
  };
  const handleClickProperty = () => {
    setOpenProperty(!openProperty);
  };
  const handleClickProjects = () => {
    setOpenProjects(!openProjects);
  };
  const handleClickKyc = () => {
    setOpenKyc(!openKyc);
  };
  const handleClickAdministrative = () => {
    setOpenAdministrative(!openAdministrative);
  };

  return (
    <div
      className={Styles.main}
      style={viewFullMenu ? { width: "300px" } : { width: "72px" }}
    >
      <div className={Styles.nav}>
        <div>
          {viewFullMenu ? (
            <ToggleButton
              value="left"
              aria-label="left aligned"
              onClick={() => {
                setViewFullMenu(false);
              }}
            >
              <FormatAlignLeftIcon />
            </ToggleButton>
          ) : (
            <ToggleButton
              value="right"
              aria-label="right aligned"
              onClick={() => {
                setViewFullMenu(true);
              }}
            >
              <FormatAlignRightIcon />
            </ToggleButton>
          )}
        </div>

        <Link to="/" className={Styles.dashboard}>
          Dashboard
        </Link>
      </div>

      <List sx={{ width: "100%" }} component="nav">

        {/* menu for user */}
        <ListItemButton onClick={handleClickUser}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="User" />
          {openUser ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openUser} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{ bgcolor: "background.paper" }}
          >
            <ListItem text="All Users" link="/users" icon={PersonIcon} />
            <ListItem text="Create" link="/users/create" icon={AddBoxIcon} />
            <ListItem text="Permissions" link="/users/permissions" icon={GppGoodIcon} />
            <ListItem text="Roles" link="/users/roles" icon={AltRouteIcon} />
          </List>
        </Collapse>

        {/* menu for cms */}
        <ListItemButton onClick={handleClickCMS}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="CMS" />
          {openCMS ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openCMS} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{ bgcolor: "background.paper" }}
          >
            <ListItem text="Home" link="/cms/1-H" icon={HomeIcon} />
            <ListItem text="Property" link="/cms/1-M" icon={ApartmentIcon} />
            <ListItem text="Get Started" link="/cms/1-P" icon={PlayArrowIcon} />
            <ListItem text="About Us" link="/cms/1-I" icon={InfoIcon} />
            <ListItem text="FAQ" link="/cms/1-J" icon={QuizIcon} />
            <ListItem text="Contact Us" link="/cms/1-K" icon={PhoneIcon} />
            <ListItem text="Footer" link="/cms/1-L" icon={AnchorIcon} />
            <ListItem text="Manage Modules" link="/cms/manage" icon={ManageAccountsIcon} />
          </List>
        </Collapse>

        {/* menu for property */}
        <ListItemButton onClick={handleClickProperty}>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Property" />
          {openProperty ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openProperty} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{ bgcolor: "background.paper" }}
          >
            <ListItem text="All Properties" link="properties" icon={ApartmentIcon} />
            <ListItem text="Create" link="properties/create" icon={AddCircleIcon} />
          </List>
        </Collapse>

        {/* menu for projects */}
        <ListItemButton onClick={handleClickProjects}>
          <ListItemIcon>
            <WidgetsIcon />
          </ListItemIcon>
          <ListItemText primary="Projects" />
          {openProjects ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openProjects} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{ bgcolor: "background.paper" }}
          >
            <ListItem text="All Projects" link="projects/allProjects" icon={InventoryIcon} />
            <ListItem text="Call Back Requests" link="projects/callBackRequests" icon={CallMissedIcon} />
          </List>
        </Collapse>

        {/* menu for clientsite kyc */}
        <ListItemButton onClick={handleClickKyc}>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Kyc" />
          {openKyc ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openKyc} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{ bgcolor: "background.paper" }}
          >
            <ListItem text="Eligibility" link="/kyc/eligibility" icon={AccessibilityIcon} />
            <ListItem text="Get Help" link="/kyc/getHelp" icon={PhoneMissedIcon} />
            <ListItem text="Contact Us" link="/kyc/contactUs" icon={PhoneMissedIcon} />
          </List>
        </Collapse>

        {/* menu for administrative tasks */}
        <ListItemButton onClick={handleClickAdministrative}>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Administrative" />
          {openAdministrative ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openAdministrative} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{ bgcolor: "background.paper" }}
          >
            <ListItem text="Database Snapshots" link="/administrative/database-snapshots" icon={AccessibilityIcon} />
          </List>
        </Collapse>

        {/* menu for files */}
        <ListItem text="Files" link="/files" icon={AccessibilityIcon} />
        {/* menu for logout */}
        <ListItem text="Logout" link="/logout" icon={ExitToAppIcon} />
      </List>
    </div>
  );
};
